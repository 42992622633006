import React from "react"

const SpeakerCard = props => {
  const { onClick = null, imgSrc = "", name = "", company = "" } = props

  return (
    <div
      className={`card card--v2 card--clickable speaker-card mb-20  background-color-monochrome-1 _a_speaker`}
      onClick={onClick}
      data-name={`${name}`}
    >
      <div className="media">
        <div className={"image image--background "}>
          <img src={imgSrc} className="img" alt="speaker image" />
        </div>
      </div>
      <div className="description pt-15 pb-15 pr-5 pl-5 text-align-center background-color-monochrome-1">
        <h4 className="mb-10 text-size-medium font-secondary-bold color-secondary-2 _a_speaker_name">
          {name}
        </h4>
        <div className="text text-size-medium color-secondary-2 font-secondary-regular pt-10 company-name">
          {company}
        </div>
      </div>
    </div>
  )
}

export default React.memo(SpeakerCard)
